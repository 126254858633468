import Product from "./product.page";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRef } from "react";
import Conditional from "./account/conditional.page";

const Products = (props) => {

  const location = useLocation();
  const previousPath = useRef(location.state?.previousPath);
  const prevScrollPositionY = useRef(location.state?.scrollPositionY);

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchData, setSearchData] = useState(props.searchData);

  const [pagesData, setPagesData] = useState(props.searchData);

  const [activePage, setActivePage] = useState(1);

  useEffect(() => {

    if (previousPath.current) {
      if ((previousPath.current.length > 10) && (previousPath.current.slice(0, 10) === "/products/")) {
        if (searchData === 0) {
          props.getProducts(searchParams.toString());
          window.scrollTo(0, 0);
        }
        previousPath.current = undefined;
      } else {
        props.getProducts(searchParams.toString());
        window.scrollTo(0, 0);
      }
    } else {
      props.getProducts(searchParams.toString());
      window.scrollTo(0, 0);
    }

    if (searchParams.get('page')) {
      setActivePage(parseFloat(searchParams.get('page')));
    }
    else {
      setActivePage(1);
    }

  }, [searchParams]);

  
  useEffect(() => {
    setSearchData(props.searchData);
    setPagesData(props.pagesData);
  }, [props.searchData, props.pagesData])


  useEffect(() => {
    if (prevScrollPositionY.current && (searchData !== 0)) { window.scrollTo(0, prevScrollPositionY.current); prevScrollPositionY.current = undefined };
  });

  return (
    <div className="flex flex-col pt-10 lg:pt-11 " >
      <div className="grid grid-cols-2 gap-6 lg:gap-10 w-full py-3 mt-28 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 px-4 lg:px-14">
        {
          (Object.keys(searchData).map(product => {
            return <Product searchParams={searchParams.toString()} cartCode={props.cartCode} stock={searchData[product].stock} key={searchData[product]._id} _id={searchData[product]._id} name={searchData[product].name} url={searchData[product].url} price={searchData[product].price} purPrice={searchData[product].purPrice} photos={searchData[product].photos} addToCart={props.addToCart} />
          }))
        }
      </div>
      <div className="flex w-full flex-row gap-2 text-sm sm:text-tiny justify-center items-center my-8">
        <div className={"flex text-center shadow-sm justify-center items-center " + ((activePage === 1) ? 'bg-curated-gray' : 'bg-curated-blue')}>
          <button onClick={() => { searchParams.set('page', activePage - 1); setSearchParams(searchParams);  }} disabled={activePage === 1} className="text-white text-center w-14 py-1 pr focus:outline-none" >Prev</button>
        </div>
        {(() => {
          let pagesDiv = [];
          for (let i = 1; i <= pagesData; i++) {
            pagesDiv.push(<button key={i} id={i} onClick={() => { setActivePage(i); searchParams.set('page', i); setSearchParams(searchParams); }} className={"px-3 py-0.5 " + ((activePage === i) ? "text-curated-blue border border-curated-blue" : "text-search-bar-text")}>{i}</button>)
          }
          return pagesDiv;
        })()}
        <div className={"flex text-center shadow-sm justify-center items-center " + (((activePage + 1) > pagesData) ? 'bg-curated-gray' : 'bg-curated-blue')}>
          <button onClick={() => { searchParams.set('page', activePage + 1); setSearchParams(searchParams); }} disabled={(activePage + 1) > pagesData} className="text-white text-center w-14 py-1 pr focus:outline-none" >Next</button>
        </div>
      </div>
    </div>
  );
}


export default Products;