const Header = (props) => {

    return (
        <div className="bg-[url('../img/newsletter.webp')] bg-cover bg-origin-border bg-center " >
            <div className="flex flex-col bg-opacity-40 bg-search-bar-text w-full h-full items-center justify-center">
            <div className="flex flex-col items-center justify-center pt-2 pb-5 h-[30rem]">
            <div id="fd-form-6361f40cea677d40c4c2e685"></div>
            {window.fd('form', {formId: '6361f40cea677d40c4c2e685', containerEl: '#fd-form-6361f40cea677d40c4c2e685'})}
            </div>
            </div>
        </div>
    )
}

export default Header;