import {Fragment, useState, useEffect, useRef, useContext, useSearchParams } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import Product from "./product.page";
import { ReactComponent as BackIcon } from '../img/back-icon.svg';
import { ReactComponent as StarIcon } from '../img/star-icon.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import cartDataContext from "../cartDatacontext";


const ProductDetails = (props) => {


    
    const [adding, setAdding] = useState("Add To Cart");
    const location = useLocation();
    const navigate = useNavigate();
    const inputQnt = useRef();
    const [productData, setProductData] = useState([{ name: "", description: "",type: "", category: "", subCategory:"", price: 0, reviews: [], photos: [] }]);
    const [similarData, setSimilarData] = useState(0);
    let images = [];
    const [ gallery, setGallery ] = useState();
    const {cartData} = useContext(cartDataContext);
    const prevSearchParams = location.state?.prevSearchParams;
    const prevPath = location.state?.previousPath;
    const scrollPositionY = useRef((location.state?.scrollPositionY) ? location.state?.scrollPositionY : 0);

    useEffect(() => {

        getSimilar();

    }, []);

    useEffect(() => {

        window.scrollTo(0, 0);
        getProduct();

    }, [location]);


    useEffect(() => {
        
        images = []

          for (let i = 0; i < productData[0].photos.length; i++) {
    
        images.push({
            renderItem: (src) => RenderItem(src = productData[0].photos[i].image_url),
            thumbnail: productData[0].photos[i].thumbnail_url,
        }
        )
    }   
   setGallery(<ImageGallery showBullets={true} showFullscreenButton={true} showPlayButton={false} showThumbnails={true} lazyLoad={true} items={images} />)
}, [productData]);

useEffect(() => {

    if(adding==="Adding ..."){
    if(props.cartCode.id === productData[0]._id){
        if(props.cartCode.status === 200){
            setAdding("Added!");
            setTimeout(()=>{setAdding("Add To Cart")},"2000")
        }
        else if(props.cartCode.status === 209){
            setAdding("Out Of Stock");
            setTimeout(()=>{setAdding("Add To Cart")},"2000")
        }
        else if(props.cartCode.status === 211){
            setAdding(productData[0].stock+" in Stock");
            setTimeout(()=>{setAdding("Add To Cart")},"2000")
        }
        else{
            setAdding("Error No Added!");
            setTimeout(()=>{setAdding("Add To Cart")},"2000")
        }
    }

}
    

}, [props.cartCode, props._id]);


    

    const getSimilar = async () => {

        let url;

        if (props.path){
            url = props.path;
        }else{
            url = location.pathname;
        }

        axios.get('https://api.curatedhomeconcept.com/similar-products/'+url.replace("/products/",""), {
        })
            .then(res => {

                let data = JSON.stringify(res.data);
                data = JSON.parse(data);
                setSimilarData(data);

            })
            .catch(error => {
                console.error(error);
            });
    }

    const getProduct = async () => {

        let url;

        if (props.path){
            url = props.path;
        }else{
            url = location.pathname;
        }

        axios.get('https://api.curatedhomeconcept.com'+url, {
        })
            .then(res => {

                let data = JSON.stringify(res.data);
                data = JSON.parse(data);
                setProductData(data);

            })
            .catch(error => {
                console.error(error);
            });
    }

    return (<div>
         <BackIcon onClick={() => {navigate(((prevSearchParams) ? "/products/?"+prevSearchParams : (prevPath==="/products/") ? "/products/" : -1),{ state: { previousPath: location.pathname, scrollPositionY: scrollPositionY.current } })}} className="h-6 fixed mt-20 md:mt-20 ml-5 hover:cursor-pointer hover:fill-curated-blue hover:stroke-curated-blue z-20" />
        <div className="flex flex-cols items-center justify-center">
            <div className="flex gap-4 md:gap-10 flex-col lg:flex-row items-start  justify-center w-full px-6 lg:px-28   mt-20">
                <div className="flex w-full lg:w-1/2 items-center justify-center">
                 {gallery}
                </div>
                <div className="flex flex-col w-full lg:w-1/2 gap-0 h-full ">
                <p className="text-lg md:text-xl">{productData[0].name}</p>
                    <div className="flex flex-row text-sm md:text-md">
                    <Link to={"/products/?collection="+((productData[0].type).replaceAll(" ","+")).replaceAll("&","%26")}><p >{productData[0].type}</p></Link>&nbsp;/&nbsp;
                    <Link to={"/products/?category="+((productData[0].category).replaceAll(" ","+")).replaceAll("&","%26")}><p >{productData[0].category}</p></Link>&nbsp;/&nbsp;
                    <Link to={"/products/?category="+((productData[0].category).replaceAll(" ","+")).replaceAll("&","%26")+"&subCategory="+((productData[0].subCategory).replaceAll(" ","+")).replaceAll("&","%26")}><p >{productData[0].subCategory}</p></Link>
                    </div>
                    <p className="text-sm md:text-md">{"SKU: CHC"+String(productData[0].SKU).padStart(7,"0")}</p>
                    {(productData[0].type !== "Outlet") ?
                    <div className="flex flex-row gap-1 w-full pb-3">
                        <div className="flex justify-start items-center grow">
                            <StarIcon className="h-5" key="star1" />
                            <StarIcon className="h-5" key="star2" />
                            <StarIcon className="h-5" key="star3" />
                            <StarIcon className="h-5" key="star4" />
                            <StarIcon className="h-5" key="star5" />
                        </div>
                        <p className="text-tiny text-search-bar-text">{productData[0].reviews.length} reviews</p>
                    </div> : null
                    }
                    <hr></hr>
                    <p className="text-tiny  my-6">{productData[0].description}</p>
                    <div className="flex flex-row w-fit">
                        <div className="flex w-full justify-start items-start">
                        <div className="flex  flex-row border text-search-bar-text border-search-bar-text ">
                            <button onClick={() => {if((parseFloat(inputQnt.current.value)-1) >= 1){inputQnt.current.value--}}} className="px-2">-</button>
                            <input ref={inputQnt} readOnly={true} className="text-center text-sm w-5 mt-0.5 text-black appearance-none focus:outline-none" defaultValue={1}/>
                            <button onClick={() => {if((parseFloat(inputQnt.current.value)+1) <= parseFloat(productData[0].stock)){inputQnt.current.value++}}} className="px-2">+</button>
                        </div>
                    </div>
                        <p className="text-tiny text-search-bar-text ml-2 w-full">{(productData[0].stock > 0) ? productData[0].stock + " In Stock" : "Sold Out" } </p>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <div className="flex flex-row w-full items-center">
                        <div className="flex flex-col justify-start">
                        <p className="text-tiny line-through">${productData[0].purPrice}</p>
                        <p className="text-tiny ">${productData[0].price}</p>
                        </div>
                            <div className="flex w-full justify-end">
                            <div className={"flex w-2/5 justify-center  flex-row border  px-2 h-1/2 "+(((adding==="Add To Cart") && (productData[0].stock>0)) ? "transition ease-out duration-500 border-curated-blue bg-curated-blue" : (adding==="Add To Cart") ? " transition ease-out duration-500 border-curated-gray bg-curated-gray" : (adding==="Added!") ? "transition ease-out duration-500 border-green-700 bg-green-700"  : (adding==="Adding ...") ? " transition ease-out duration-500 border-curated-gray bg-curated-gray" : "transition ease-out duration-500 border-red-700 bg-red-700")}>
                                <button disabled={(!(adding==="Add To Cart") || !(productData[0].stock>0))} onClick={(e) => {e.preventDefault(); props.addToCart(productData[0]._id, inputQnt.current.value); setAdding("Adding ..."); }} className="w-full text-white text-xs md:text-sm text-center  
                          focus:outline-none" >{adding}</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col items-center justify-center py-10 px-4 md:px-20">
        <p className="text-lg md:text-3xl mb-4 mt-5">{similarData[0] ? "Similar Products" : ""}</p>
        <div className="grid grid-cols-2 gap-5 md:gap-10 w-full py-3 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 items-center justify-center">
                {
                    (Object.keys(similarData).map(product => {
                        return <Product searchParams={prevSearchParams} cartCode={props.cartCode} stock={similarData[product].stock} key={similarData[product]._id} _id={similarData[product]._id} name={similarData[product].name} url={similarData[product].url} purPrice={similarData[product].purPrice}  price={similarData[product].price} photos={similarData[product].photos} addToCart={props.addToCart}/>
                    }))
                }
            </div>
        </div>
        {(productData[0].type !== "Outlet") ?
        <div className="flex flex-row pl-14">
            <p className="text-xl mb-10">Reviews</p><p className="text-base mt-1.5">{productData[0].reviews.length}</p>
        </div> : null
        }
    </div>
    );
}

const RenderItem = (src) => {
    return (<div className="flex items-center h-[16rem] sm:h-[18rem] md:h-[20rem] lg:h-[22rem] justify-center">
        <LazyLoadImage src={src} effect="opacity" className="w-40 sm:w-52 md:w-64 lg:w-72"></LazyLoadImage>
        </div>
    );
}


export default ProductDetails;