import { ReactComponent as FacebookLogo } from '../../img/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../img/instagram-logo.svg';
import { ReactComponent as PinterestLogo } from '../../img/pinterest-logo.svg';
import { ReactComponent as HouzzLogo } from '../../img/houzz-logo.svg';

const Social = () => {
    return (
            <div className="flex flex-col gap-5 pt-3 -mt-3 pb-5 bg-white shadow-sm">
                <a href="https://www.instagram.com/curatedhomeconcept/" className="w-fit h-fit"><InstagramLogo alt="instagram-logo" className="h-6 hover:fill-curated-blue" /></a>
                <a href="https://www.facebook.com/curatedhomeconcept" className="w-fit h-fit"><FacebookLogo alt="facebook-logo" className="h-6 hover:fill-curated-blue" /></a>
                <a href="https://www.pinterest.com/thinkcurated/" className="w-fit h-fit"><PinterestLogo alt="pinterest-logo" className="h-6 hover:fill-curated-blue" /></a>
                <a href="https://www.houzz.com/professionals/interior-designers-and-decorators/curated-pfvwus-pf~1101153491" className="w-fit h-fit"><HouzzLogo alt="houzz-logo" className="h-6 hover:fill-curated-blue" /></a>
            </div>
    );
}

export default Social;