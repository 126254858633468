import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function AboutUs(props) {

    let location = useLocation();

    useEffect(() => {

        window.scrollTo(0, 0);

    }, [location]);

    return (
        <div className="w-full h-full ">
            <div className="pt-14 ">
                <img alt="Curated. Team" src="curatedTeam.webp" className="w-full h-52 sm:h-72 md:h-80 lg:h-[32rem] object-cover" />
            </div>
            <div className="flex w-full flex-col lg:flex-row items-start px-4 lg:px-14 gap-8 mt-6">
                <div className="flex flex-col w-full gap-4 items-center justify-center">
                    <p className="text-base md:text-xl lg:text-3xl font-semibold lg:font-medium">About Us</p>
                    <p className="text-tiny md:text-base "> CURATED. Home Concept is a division of The CURATED.
                        Group, a high-end interior design firm located in Miami, FL. In our projects,
                        we constantly discover amazing pieces that we want to share with a broader audience
                        and bring to those who aren't redoing their entire home, but are looking for that
                        special piece to make their space perfect.
                        Our mission is simple: bringing you quality pieces that look {" & "} feel great, and doing
                        so while putting special emphasis on customer service and shipping transparency.
                        We provide stocking information that is updated daily, helping you make informed decisions
                        and stay excited about your new purchase! </p>
                </div>
                <div className="flex flex-col w-full gap-4 items-start justify-center">
                    <p className="text-base md:text-xl lg:text-3xl font-semibold lg:font-medium text-center w-full">Warehouse Showroom {" & "} Pickup</p>
                    <a href="https://maps.apple.com/maps?q=3022+NW+73rd+St+Suite+5,+Miami,+FL+33147" target="_blank" rel="noopener noreferrer">
                        <p className="underline text-tiny md:text-base "> 3022 NW 73rd St, Suite 5. Miami, FL 33147.</p>
                    </a>
                    <p className=" text-tiny md:text-base"> Monday-Friday 9am-5pm.</p>
                    <p className="text-tiny md:text-base"> Pickups are free! Please bring your ID when you pick up your order. The ID must match the name on the order. For faster processing please call <a href="tel:+1 (305) 615-5141" className="underline">+1 (305) 615-5141</a> to reserve your pickup window.</p>
                </div>
            </div>
            <div className="flex flex-row mb-6 mt-6 px-4 lg:px-14 gap-6 ">
                <img alt="Curated. Warehouse" src="warehouse.webp" className="w-2/5 object-cover" />
                <div className="flex w-3/5 items-center justify-center ">
                    <iframe title="Curated. in Map" style={{ border: 0 }} className="flex w-full h-40 md:h-52 lg:h-96" loading="lazy" allowFullScreen="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d123847.16578841142!2d-80.28825281020151!3d25.794369759840468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b0efad73af2d%3A0x389df07e92b8b9d8!2s3022%20NW%2073rd%20St%2C%20Miami%2C%20FL%2033147!5e0!3m2!1ses-419!2sus!4v1665765311801!5m2!1ses-419!2sus" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;