import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState, useEffect } from "react";
import { ReactComponent as DeleteIcon } from '../../img/delete-icon.svg';

const Cart = (props) => {

    const [subtotal, setSubtotal] = useState(0)
    const [taxes, setTaxes] = useState(0);
    const [total, setTotal] = useState(0);

    const [shippingActive, setShippingActive] = useState(false);

    const [cartActive, setCartActive] = useState(false);

    useEffect(() => {

        if (props.cartData.products) {

            setSubtotal(props.cartData.subTotal);
            setTotal(props.cartData.total);
            setTaxes((props.cartData.totalTaxes + props.cartData.stripeFee).toFixed(2));
            setCartActive(true);
            if (props.cartData.products[0]) {
                setShippingActive(true);
            } else { setShippingActive(false); }
        }
        else {
            setShippingActive(false);
            setSubtotal(0);
            setTotal(0);
        }

    }, [props]);


    return (
        <div className="flex flex-col w-auto h-full bg-white mt-5">
            <div className="flex flex-col md:flex-row h-full gap-6">
                <div className="flex flex-col w-full md:w-3/5  gap-3">
                    <p className="font-semibold text-tiny">Shopping Cart</p>
                    <hr className="h1" />
                    <div className="flex flex-col h-36 md:h-[27rem] overflow-y-scroll scrollbar-hide gap-3">
                        {cartActive &&
                            (Object.keys(props.cartData.products).map(item => {
                                return <Item key={props.cartData.products[item]._id} productId={props.cartData.products[item].productId} photo={props.cartData.products[item].photo} name={props.cartData.products[item].name} quantity={props.cartData.products[item].quantity} price={props.cartData.products[item].price} totalPrice={props.cartData.products[item].totalPrice} supInCart={props.supInCart} addToCart={props.addToCart} />
                            }))
                        }</div>
                </div>
                <div className="flex flex-col w-full md:w-2/5 gap-3 ">
                    <p className="font-semibold text-tiny">Summary</p>
                    <hr className="h-1" />
                    <div className="flex flex-row">
                        <div className="flex flex-col gap-4 ">
                            <p className="text-tiny">Subtotal</p>
                            <p className="text-tiny ">Shipping</p>
                            <p className="text-tiny">Tax {'&'} Fees</p>
                        </div>
                        <div className="flex flex-col gap-4 grow text-right justify-end">
                            <p className="text-tiny">${subtotal.toFixed(2)}</p>
                            <p className="text-tiny">At Next Step</p>
                            <p className="text-tiny">${taxes}</p>
                        </div>
                    </div>
                    <hr className="h-1" />
                    <div className="flex flex-row">
                        <div className="flex flex-col gap-4 ">
                            <p className=" text-tiny font-semibold">Total</p>
                        </div>
                        <div className="flex flex-col gap-4 w-full text-right justify-end">
                            <p className=" text-tiny font-semibold ">${total.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex items-end w-full h-full justify-end grow">
                    <div className={"flex w-52 shadow-sm " + (!shippingActive ? 'bg-curated-gray' : 'bg-curated-blue')}>
                        <button disabled={!shippingActive} onClick={() => { props.next() }} className="text-white text-tiny w-full text-center 
                        py-2 pr focus:outline-none" >Next: Shipping</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

const Item = (props) => {
    return (
        <div className="flex gap-4 items-center">
            <div className="flex w-12 h-12 items-center justify-center ">
                <div className="flex h-full">
                    <LazyLoadImage src={props.photo} className="h-full" visibleByDefault={props.photo === "/img.jpg"} />
                </div>
            </div>
            <div className="flex flex-col gap-1 w-full">
                <p className="text-base">{props.name}</p>
                <div className="flex flex-row w-full items-center">
                    <p className="text-tiny">${props.totalPrice.toFixed(2)}</p>
                    <div className="flex w-full justify-end items-end">
                        <div className="flex  flex-row border text-search-bar-text border-search-bar-text ">
                            <button onClick={() => { props.supInCart(props.productId, 1) }} className="px-2">-</button>
                            <p className="text-sm w-2 mt-0.5 text-black">{props.quantity}</p>
                            <button onClick={() => { props.addToCart(props.productId, 1) }} className="px-2">+</button>
                        </div>
                        <DeleteIcon onClick={() => { props.supInCart(props.productId, props.quantity) }} className="h-4 hover:fill-curated-blue" />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Cart;