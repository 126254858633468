import { Link, useLocation } from "react-router-dom";

const Account = () => {

    const { pathname } = useLocation();

    return (
            <div className="flex w-full flex-col gap-5 pb-5 bg-white shadow-sm text-center text-tiny">
                <Link to="/signup" state={{ previousPath: pathname }}><p className="px-5 hover:text-curated-blue">Sign Up</p></Link>
                <Link to="/login" state={{ previousPath: pathname }}><p className="px-5 hover:text-curated-blue">Log In</p></Link>
            </div>
    );
}

export default Account;