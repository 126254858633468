import { BrowserRouter, Routes, Route } from "react-router-dom";
import { React, useState, useRef, useEffect, createContext } from 'react';
import axios from 'axios';
import Header from './pages/components/header.component';
import Footer from './pages/components/footer.component';
import Newsletter from './pages/components/newsletter.component';
import Home from './pages/home.page';
import ProductDetails from './pages/productDetails.page';
import Products from './pages/products.page';
import AboutUs from './pages/aboutUs.page';
import TermsConditions from './pages/terms.page';
import PrivacyPolicy from './pages/privacy.page';
import SignUp from './pages/account/signUp.page';
import LogIn from './pages/account/logIn.page';
import CheckOut from './pages/checkout.pages';
import Conditional from './pages/account/conditional.page';
import cartDataContext from "./cartDatacontext";
import PaymenSuccessful from "./pages/payment-successful.page";

function App() {

  const accessToken = useRef();
  const refreshToken = useRef();

  const [cartData, setCartData] = useState(0);
  const [productData, setProductData] = useState(0);

  const [searchData, setSearchData] = useState(0);
  const [pagesData, setPagesData] = useState(0);

  const [orderData, setOrderData] = useState(0);
  const [cartCode, setCartCode] = useState({ id: "0", status: 0 });


  useEffect(() => {

    if (localStorage.getItem("refreshToken")) {
      refreshToken.current = localStorage.getItem("refreshToken");
    }

    if (sessionStorage.getItem("accessToken")) {
      accessToken.current = sessionStorage.getItem("accessToken");
    }

  }, []);

  const registerEmail = async (fname, lname, email, password) => {

    axios.post('https://api.curatedhomeconcept.com/auth/register', {
      firstName: fname,
      lastName: lname,
      email: email,
      password: password
    })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);

        localStorage.setItem("refreshToken", data.refreshToken);
        sessionStorage.setItem("accessToken", data.accessToken)
        refreshToken.current = data.refreshToken;
        accessToken.current = data.accessToken;


      })
      .catch(error => {
        console.error(error);
      });
  }

  const registerDeviceId = async (functionP, param, param2) => {

    axios.post('https://api.curatedhomeconcept.com/auth/register', {

    })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);
        if (data.deviceId) {
          localStorage.setItem("deviceId", data.deviceId);
        }

        localStorage.setItem("refreshToken", data.refreshToken);
        sessionStorage.setItem("accessToken", data.accessToken)
        refreshToken.current = data.refreshToken;
        accessToken.current = data.accessToken;

        if (functionP === "addToCart") {
          addToCart(param, param2);
        }
        else if (functionP === "supInCart") {
          supInCart(param, param2);
        }


      })
      .catch(error => {
        console.error(error);
      });
  }

  const loginEmail = async (email, password) => {

    axios.post('https://api.curatedhomeconcept.com/auth/token', {
      email: email,
      password: password
    })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);

        localStorage.setItem("refreshToken", data.refreshToken);
        sessionStorage.setItem("accessToken", data.accessToken)
        refreshToken.current = data.refreshToken;
        accessToken.current = data.accessToken;


      })
      .catch(error => {
        console.error(error);
      });
  }

  const loginDeviceId = async (deviceId) => {

    axios.post('https://api.curatedhomeconcept.com/auth/token', {
      deviceId: deviceId
    })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);
        setCartData(data);


      })
      .catch(error => {
        registerDeviceId();
        console.error(error);
      });
  }

  const funRefreshToken = async (functionP, param, param2, param3, param4, param5, param6) => {

    axios.post('https://api.curatedhomeconcept.com/auth/token', "", {
      headers: {
        'x-access-token': refreshToken.current
      }
    })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);

        sessionStorage.setItem("accessToken", data.accessToken);
        accessToken.current = data.accessToken;

        if (functionP === "getCart") {
          getCart();
        }
        else if (functionP === "addToCart") {
          addToCart(param, param2);
        }
        else if (functionP === "supInCart") {
          supInCart(param, param2);
        }
        else if (functionP === "processPayment") {
          processPayment(param, param2, param3, param4, param5, param6);
        }
        else if (functionP === "checkOrder") {
          checkOrder();
        }


      })
      .catch(error => {
        loginDeviceId(localStorage.getItem("deviceId"));
        console.error(error);
      });
  }

  const getCart = async () => {

    axios.get('https://api.curatedhomeconcept.com/cart', {
      headers: {
        'x-access-token': accessToken.current
      }
    })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);
        setCartData(data);


      })
      .catch(error => {
        funRefreshToken("getCart");
      });
  }


  const addToCart = async (id, qnt) => {

    if (refreshToken.current) {

      axios.put('https://api.curatedhomeconcept.com/cart',
        {
          productId: id,
          quantityAdd: qnt
        },
        {
          headers: {
            'x-access-token': accessToken.current
          }
        })
        .then(res => {

          let data = JSON.stringify(res.data);
          data = JSON.parse(data);
          setCartData(data);
          setCartCode({ "id": id, "status": res.status });
          if (localStorage.getItem("activeWinChkt")) {
            localStorage.removeItem("activeWinChkt");
          }


        })
        .catch(error => {
          funRefreshToken("addToCart", id, qnt);
        });
    } else {
      registerDeviceId("addToCart", id, qnt);
    }
  }

  const supInCart = async (id, qnt) => {

    if (refreshToken.current) {

      axios.post('https://api.curatedhomeconcept.com/cart',
        {
          productId: id,
          quantityAdd: qnt
        },
        {
          headers: {
            'x-access-token': accessToken.current
          }
        })
        .then(res => {

          let data = JSON.stringify(res.data);
          data = JSON.parse(data);
          setCartData(data);
          if (localStorage.getItem("activeWinChkt")) {
            localStorage.removeItem("activeWinChkt");
          }

        })
        .catch(error => {
          funRefreshToken("supInCart", id, qnt);
        });
    } else {
      registerDeviceId("supInCart", id, qnt);
    }
  }

  const getFeatures = async () => {

    axios.get('https://api.curatedhomeconcept.com/products', {
    })
      .then(res => {

        let data = JSON.stringify(res.data.products);
        data = JSON.parse(data);
        setProductData(data);


      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {

    if (refreshToken.current) {
      getCart();
    }

    getFeatures();


  }, []);

  const [clientSecret, setClientSecret] = useState("");

  const processPayment = async (fname, lname, email, phone, address, delivery) => {

    axios.post('https://api.curatedhomeconcept.com/order',
      {
        firstName: fname,
        lastName: lname,
        email: email,
        phone: phone,
        address: address,
        delivery: delivery
      }
      , {
        headers: {
          'x-access-token': accessToken.current
        }
      })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);
        setClientSecret(data.clientSecret);

      })
      .catch(error => {
        funRefreshToken("processPayment", fname, lname, email, phone, address, delivery);
      });
  }

  const checkOrder = async () => {

    axios.get('https://api.curatedhomeconcept.com/order',
      {
        headers: {
          'x-access-token': accessToken.current
        }
      })
      .then(res => {

        let data = JSON.stringify(res.data);
        data = JSON.parse(data);
        setOrderData(data);

      })
      .catch(error => {
        funRefreshToken("checkOrder");
      });
  }

  const getProducts = async (searchParams) => {

    axios.get('https://api.curatedhomeconcept.com/products/?' + searchParams
      , {
      })
      .then(res => {

        let data = JSON.stringify(res.data.products);
        let pages = JSON.stringify(res.data.pages);
        data = JSON.parse(data);
        pages = JSON.parse(pages);
        setPagesData(pages);
        setSearchData(data);


      })
      .catch(error => {
        console.error(error);
      });
  }


  return (
    <BrowserRouter>
    <cartDataContext.Provider value={{ cartData, setCartData }}>
      <Header orderData={orderData} checkOrder={checkOrder} processPayment={processPayment} clientSecret={clientSecret} cartData={cartData} getCart={getCart} addToCart={addToCart} supInCart={supInCart} registerEmail={registerEmail} loginEmail={loginEmail} />
      <aside>
        <Routes>
          <Route path="/signup" element={<SignUp signUpAccount={registerEmail}/>}/>
          <Route path="/login" element={<LogIn loginAccount={loginEmail}/>}/>
          <Route path="/checkout" element={<CheckOut orderData={orderData} checkOrder={checkOrder} processPayment={processPayment} clientSecret={clientSecret} cartData={cartData} getCart={getCart} addToCart={addToCart} supInCart={supInCart} />}/>
          <Route path="/payment-successful" element={<PaymenSuccessful />}/>      
        </Routes>
      </aside>
      <Routes>
        <Route path="*" element={<Home status={404}/>} />
        <Route path="/" element={<Home cartCode={cartCode} productData={productData} cartData={cartData} getFeatures={getFeatures} addToCart={addToCart} />}/>
        <Route path="/payment-successful" element={<Conditional getProducts={getProducts} searchData={searchData} pagesData={pagesData} cartCode={cartCode} productData={productData} cartData={cartData} getFeatures={getFeatures} addToCart={addToCart}/>}/>  
        <Route path="/signup" element={<Conditional getProducts={getProducts} searchData={searchData} pagesData={pagesData} cartCode={cartCode} productData={productData} cartData={cartData} getFeatures={getFeatures} addToCart={addToCart}/>}/>
        <Route path="/login" element={<Conditional getProducts={getProducts} searchData={searchData} pagesData={pagesData} cartCode={cartCode} productData={productData} cartData={cartData} getFeatures={getFeatures} addToCart={addToCart}/>}/>
        <Route path="/checkout" element={<Conditional getProducts={getProducts} searchData={searchData} pagesData={pagesData} cartCode={cartCode} productData={productData} cartData={cartData} getFeatures={getFeatures} addToCart={addToCart}/>}/>
        <Route path="/products/*" element={<ProductDetails cartCode={cartCode} addToCart={addToCart} />} />
        <Route path="/products/" element={<Products getProducts={getProducts} searchData={searchData} pagesData={pagesData} cartCode={cartCode} getFeatures={getFeatures} addToCart={addToCart} />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Newsletter />
      <Footer />
    </cartDataContext.Provider>
    </BrowserRouter>
  );
}

export default App;
