import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const LogIn = (props) => {

    const [emailInput, setEmailInput] = useState();
    const [passwordInput, setPasswordInput] = useState();
    const navigate = useNavigate();

    return (

        <div onClick={() => navigate("/")} className="flex fixed w-screen h-screen items-center justify-center bg-search-bar-text bg-opacity-75 z-40" >
            <div onClick={(e) => e.stopPropagation()} className="flex items-center justify-center w-96 h-auto flex-col gap-5  bg-white border border-search-bar-gray py-4  shadow-sm ">
                <div className="flex flex-row mt-5"><p className="text-lg text-center text-black">Thank you for your order! Please check your email for further information.</p>
                </div>
                <div className="flex w-32 bg-curated-blue border border-curated-blue shadow-sm ">
                    <button onClick={() => navigate("/")} className="text-white w-80 bg-curated-blue border-search-bar-gray 
                       py-2 pr focus:outline-none sm:text-sm" >Done</button>
                </div>
            </div>
        </div>

    );
}



export default LogIn;