import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState, useEffect } from "react";
const Shipping = (props) => {

    const [fnameInput, setfnameInput] = useState();
    const [lnameInput, setlnameInput] = useState();
    const [emailInput, setemailInput] = useState();
    const [phoneInput, setphoneInput] = useState();
    const [addressOneInput, setAddressOneInput] = useState();
    const [addressTwoInput, setAddressTwoInput] = useState();
    const [zipInput, setZipInput] = useState();
    const [cityInput, setCityInput] = useState();
    const [stateInput, setStateInput] = useState();

    const[shippingInput, setShippingInput] = useState();

    const [subtotal, setSubtotal] = useState(159.99)
    const [taxes, setTaxes] = useState(0);
    const [total, setTotal] = useState(159.99);

    const [paymentActive, setPaymentActive] = useState(false);

    const [cartActive, setCartActive] = useState(false);

    useEffect(() => {

        if (props.cartData.products) {
            setSubtotal(props.cartData.subTotal);
            setTotal(props.cartData.total);
            setTaxes((props.cartData.totalTaxes+props.cartData.stripeFee).toFixed(2));
            setCartActive(true);
            if (props.cartData.products[0]) {
                setPaymentActive(true);
            } else { setPaymentActive(false); }
        }
        else {
            setPaymentActive(false);
            setSubtotal(0);
            setTotal(0);
        }

        if(props.orderData.firstName){
            setfnameInput(props.orderData.firstName);
            setlnameInput(props.orderData.lastName);
            setemailInput(props.orderData.email);
            setphoneInput(props.orderData.phone);
            setAddressOneInput(props.orderData.address.line1);
            setAddressTwoInput(props.orderData.address.line2);
            setStateInput(props.orderData.address.state);
            setCityInput(props.orderData.address.city);
            setZipInput(props.orderData.address.zipCode);
            if(props.orderData.delivery){
                setShippingInput(props.orderData.delivery)
            }
        }

    }, [props]);

    
    useEffect(() => {

        orderCheck();
 
 
     }, []);
 
     const orderCheck = () => {
         props.checkOrder();
     }
 


    return (
        <div className="flex flex-col w-auto h-full bg-white mt-5">
            <div className="flex flex-row gap-6 w-auto h-full mb-5">
            <div className="flex grow flex-col w-3/5 gap-3">
                <p className="font-semibold text-tiny">Order Details</p>
                <hr className="" />
                <div onClick={(e) => e.stopPropagation()} className="flex items-center justify-center w-full h-auto flex-col gap-5 bg-white">
                    <div className="flex w-full flex-row gap-3">
                        <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={fnameInput} onInput={e => setfnameInput(e.target.value)} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='First Name'></input>
                        </div>
                        <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={lnameInput} onInput={e => setlnameInput(e.target.value)} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Last Name'></input>
                        </div>
                    </div>
                    <div className="flex w-full flex-row gap-3">
                        <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={emailInput} onInput={e => setemailInput(e.target.value)} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Email'></input>
                        </div>
                        <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={phoneInput} type="tel" onInput={e => setphoneInput(formatPhoneNumber(e.target.value))} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Phone Number'></input>
                        </div>
                    </div>
                    <div className="flex w-[100%] bg-white border border-search-bar-gray shadow-sm ">
                        <input value={addressOneInput} onInput={e => setAddressOneInput(e.target.value)} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Address Line 1'></input>
                    </div>
                    <div className="flex w-full  flex-row gap-3">
                        <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={addressTwoInput} onInput={e => setAddressTwoInput(e.target.value)}  className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Address Line 2 (optional)'></input>
                        </div>
                        <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={cityInput} onInput={e => setCityInput(e.target.value)} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='City'></input>
                        </div>
                    </div>
                    <div className="flex w-full  flex-row gap-3">
                    <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={stateInput} onInput={e => setStateInput(e.target.value)} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='State'></input>
                        </div>
                        <div className="flex w-full bg-white border border-search-bar-gray shadow-sm ">
                            <input value={zipInput} onInput={e => setZipInput(formatZipCode(e.target.value))} className="placeholder:text-search-bar-text block w-full bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Zip Code'></input>
                        </div>
                    </div>
                    <div className="flex w-full ">
                        <div className="flex w-full  flex-row gap-3  ">    
                         <div onClick={() => setShippingInput("contact")} className="flex w-full gap-2 flex-row py-2 px-3 bg-white border border-search-bar-gray shadow-sm items-center justify-center text-center cursor-pointer">
                                <input type="radio" name="shipping" checked={(shippingInput==="contact")} readOnly />
                                <label >Contact Me For Delivery Options</label>
                            </div>
                            <div onClick={() => setShippingInput("pickup")} className="flex w-full gap-2 flex-row py-2 px-3 bg-white border border-search-bar-gray shadow-sm items-center justify-center text-center cursor-pointer">
                                <input type="radio" name="shipping" checked={(shippingInput==="pickup")} readOnly />
                                <label>I Will Pickup</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-2/5 h-full gap-6">
                <div className="flex flex-col w-full gap-3">
                    <p className="font-semibold text-tiny">Shopping Cart</p>
                    <hr className="" />
                    <div className="flex flex-col h-40 overflow-y-scroll scrollbar-hide gap-3">
                        {cartActive &&
                            (Object.keys(props.cartData.products).map(item => {
                                return <Item key={props.cartData.products[item]._id} productId={props.cartData.products[item].productId} photo={props.cartData.products[item].photo} name={props.cartData.products[item].name} quantity={props.cartData.products[item].quantity} price={props.cartData.products[item].price} totalPrice={props.cartData.products[item].totalPrice} supInCart={props.supInCart} addToCart={props.addToCart} />
                            }))
                        }</div>
                </div>
                <div className="flex flex-col w-full gap-3 ">
                    <p className="font-semibold text-tiny">Summary</p>
                    <hr className="h-1" />
                    <div className="flex flex-row">
                        <div className="flex flex-col gap-4 ">
                            <p className="text-tiny">Subtotal</p>
                            <p className="text-tiny ">Shipping</p>
                            <p className="text-tiny">Tax {'&'} Fees</p>
                        </div>
                        <div className="flex flex-col gap-4 grow text-right justify-end">
                            <p className="text-tiny">${subtotal.toFixed(2)}</p>
                            <p className="text-tiny">{(shippingInput==="contact")? "We Will Contact You Soon":"$0.00"}</p>
                            <p className="text-tiny">${taxes}</p>
                        </div>
                    </div>
                    <hr className="h-1" />
                    <div className="flex flex-row">
                        <div className="flex flex-col gap-4 ">
                            <p className=" text-tiny font-semibold">Total</p>
                        </div>
                        <div className="flex flex-col gap-4 w-full text-right justify-end">
                            <p className=" text-tiny font-semibold ">${total.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="flex flex-row">
            <div className="flex items-end w-full h-full justify-start grow">
                    <div className="flex w-52 bg-curated-blue shadow-sm ">
                        <button onClick={() => { props.prev() }} className="text-white text-tiny w-full text-center 
                        py-2 pr focus:outline-none" >Prev: Shopping Cart</button>
                    </div>
                </div>
            <div className="flex items-end w-full h-full justify-end grow">
                        <div className={"flex w-52 shadow-sm " + ((!fnameInput || !lnameInput || !addressOneInput || !cityInput || !shippingInput || !stateInput || !(zipInput ? zipInput.match(/^[0-9]{5}$/im) : false)  || !(emailInput ? emailInput.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i) : false) || !(phoneInput ? phoneInput.match(/^[+]?[1]{1}?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im) : false))  ? 'bg-curated-gray':'bg-curated-blue')}>
                            <button disabled={(!fnameInput || !lnameInput || !addressOneInput || !cityInput || !shippingInput || !stateInput || !(zipInput ? zipInput.match(/^[0-9]{5}$/im) : false) || !(emailInput ? emailInput.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i) : false)  || !(phoneInput ? phoneInput.match(/^[+]?[1]{1}?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im) : false))} 
                                    onClick={() => { props.next(fnameInput, lnameInput, emailInput, phoneInput, {"line1": addressOneInput, "line2": addressTwoInput ? addressTwoInput : "", "city": cityInput, "state": stateInput, "zipCode": zipInput, "country": "US"}, shippingInput) }} className="text-white text-tiny w-full text-center 
                        py-2 pr focus:outline-none" >Next: Payment</button>
                        </div>
                    </div>
              </div>      
        </div>

    );
}

const Item = (props) => {
    return (
        <div className="flex gap-4 items-center">
            <div className="flex w-12 h-12 items-center justify-center ">
                <div className="flex h-full">
                    <LazyLoadImage src={props.photo} className="h-full" visibleByDefault={props.photo === "/img.jpg"} />
                </div>
            </div>
            <div className="flex flex-col gap-1 w-full">
                <p className="text-base">{props.name}</p>
                <div className="flex flex-row w-full items-center">
                    <p className="text-tiny">{props.quantity}{(props.quantity === 1) ? " unit" : " units"}</p>
                    <div className="flex grow justify-end items-end">
                        <div className="flex  flex-row  ">
                            <p className="text-tiny">${props.totalPrice.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function formatPhoneNumber(value) {
    if (!value) return value;
    let phoneNumber = (value.length === 1) ? "+1"+value.replace(/[^\d]/g, '') : value.replace(/[^+\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 6) return phoneNumber;
    if (phoneNumberLength < 9) {
      return `${phoneNumber.slice(0,2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(5)}`;
    }
    return `${phoneNumber.slice(0,2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
      5,
      8
    )}-${phoneNumber.slice(8, 12)}`;
  }

function formatZipCode(value){
    return value.replace(/[^\d]/g, '');
}


export default Shipping;