import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import Select from "react-select";
import { Transition } from '@headlessui/react';

import logo from '../../img/curated..svg';
import { ReactComponent as LinkIcon } from '../../img/link-icon.svg';
import { ReactComponent as AccountIcon } from '../../img/user-profile-icon.svg';
import { ReactComponent as CartIcon } from '../../img/shopping-cart-simple.svg';
import { ReactComponent as SearchIcon } from '../../img/search-icon.svg';
import { ReactComponent as MenuIcon } from '../../img/menu-icon.svg';
import { ReactComponent as BackIcon } from '../../img/back-icon.svg';
import { ReactComponent as DownArrow } from '../../img/downarrow.svg';
import Social from "../account/social.page";
import Account from "../account.page";
import Cart from '../cart.page';



const Header = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [searchInputValue, setSearchInputValue] = useState();

    const [sortInputValue, setSortInputValue] = useState();

    const [socialVisible, setSocialVisible] = useState(false);
    const [socialHover, setSocialHover] = useState(false);
    const socialHoverRef = useRef();

    const [accountVisible, setAccountVisible] = useState(false);
    const [accountHover, setAccountHover] = useState(false);
    const accountHoverRef = useRef();

    const [cartVisible, setCartVisible] = useState(false);
    const [cartHover, setCartHover] = useState(false);
    const cartHoverRef = useRef();

    const [mobileMenu, setMobileMenu] = useState(false);

    const [searchVisible, setSearchVisible] = useState(false);

    const [categoryMenu, setCategoryMenu] = useState("");
    const menuHoverRef = useRef();
    const [menuHover, setMenuHover] = useState(false);

    useEffect(() => {

        if ((location.pathname === "/signup")||(location.pathname === "/login")||(location.pathname === "/checkout")||(location.pathname === "/payment-successful")) {
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = "none";
            document.body.style.setProperty('-ms-touch-action','none');
            
        } else{
            document.body.style.overflow = 'unset';
            document.body.style.touchAction = "auto";
            document.body.style.setProperty('-ms-touch-action','auto');
            setAccountVisible(false);
            setCartVisible(false);
            setSocialVisible(false);
        }

        if (location.pathname === "/products/") {
            setSearchVisible(true);
        }
        else {
            setSearchVisible(false); 
        }

        if (window.innerWidth >= 1024) {
            setMobileMenu((window.innerWidth >= 1024) && (location.pathname==="/products/"));
        }

    }, [location]);

    useEffect(() => {

        if (searchVisible) {

            if (searchParams.get('search')) {
                setSearchInputValue(searchParams.get('search'));
            }
            else {
                setSearchInputValue("");
            }

            if (searchParams.get('sort')) {
                setSortInputValue([{ label: (searchParams.get('sort') === "priceMin") ? "Price Min" : (searchParams.get('sort') === "priceMax") ? "Price Max" : (searchParams.get('sort') === "nameAsc") ? "Name A-Z" : (searchParams.get('sort') === "nameDes") ? "Name Z-A" : "", value: searchParams.get('sort') }]);
            }
            else {
                setSortInputValue("");
            }

        }

    }, [searchParams, searchVisible, setSearchParams])

    useEffect(() => {

        accountHoverRef.current = accountHover;
        cartHoverRef.current = cartHover;
        socialHoverRef.current = socialHover;

    }, [accountHover, cartHover, socialHover])

    useEffect(() => {

        menuHoverRef.current = menuHover;

    }, [menuHover])


    return (
        <div className="fixed z-50">
            <header className={"flex w-screen  flex-col items-center  z-20 " + ((searchVisible && !cartVisible && !accountVisible && !socialVisible) ? " lg:pb-3" : "shadow-sm")} >
                <div className="flex w-full flex-row items-center bg-white z-50 py-3">
                    <MenuIcon className={"h-7 lg:hidden  pl-4 z-20 " + (mobileMenu ? "stroke-curated-blue" : "stroke-curated-gray")} onClick={() => { if (mobileMenu === false) { setAccountHover(false); setAccountVisible(false); setCartHover(false); setCartVisible(false); setMobileMenu(true) } else { setMobileMenu(false) } }} />
                    <div className="flex justify-center item-center grow lg:grow-0 lg:justify-start lg:pl-14 z-20" >
                        <Link to="/">
                            <img src={logo} alt="curated-home-concept-logo" className="w-40" />
                        </Link>
                    </div>
                    <div className="hidden lg:grid grid-cols-3 place-content-stretch grow items-center text-center justify-center gap-8 mx-6 z-20">
                        <Link className=" lg:text-lg xl:text-xl hover:text-curated-blue " to="/products/?store=Outlet">Outlet Furniture<hr /></Link>
                        <Link className=" lg:text-lg xl:text-xl hover:text-curated-blue " to="/products/?store=New+Furniture">New Furniture<hr /></Link>
                        <Link className=" lg:text-lg xl:text-xl hover:text-curated-blue " to="/about-us">About Us<hr /></Link>
                    </div>
                    <div className="flex justify-end items-center flex-row h-full gap-4 lg:pr-14  z-20">
                        <Link to="/products/">
                            <div className="flex grow-y items-center h-6 w-6">
                                {!searchVisible && (
                                    <SearchIcon alt="cart-icon" className="h-full hover:fill-curated-blue" />
                                )}
                            </div>
                        </Link>
                        <div className="flex h-full items-center" onMouseLeave={() => { if (window.innerWidth >= 1024) { setTimeout(() => { if (accountHoverRef.current === false) { setAccountVisible(false) } }, "500") } }} >
                            <AccountIcon alt="cart-icon" className={"h-6 " + (accountVisible ? "fill-curated-blue" : "fill-curated-gray")} onMouseOver={() => { if (window.innerWidth >= 1024) { setAccountVisible(true) } }} onClick={() => { if (accountVisible === false) { setCartHover(false); setCartVisible(false); setSocialHover(false); setSocialVisible(false); setMobileMenu(false); setAccountVisible(true); } else { setAccountHover(false); setAccountVisible(false); } }} />
                        </div>
                        <div className="flex h-full items-center" onMouseLeave={() => { if (window.innerWidth >= 1024) { setTimeout(() => { if (cartHoverRef.current === false) { setCartVisible(false) } }, "500") } }} >
                            <CartIcon alt="cart-icon" className={"h-6  " + (cartVisible ? "fill-curated-blue" : "fill-curated-gray")} onMouseOver={() => { if (window.innerWidth >= 1024) { setCartVisible(true) } }} onClick={() => { if (cartVisible === false) { setAccountHover(false); setAccountVisible(false); setSocialHover(false); setSocialVisible(false); setMobileMenu(false); setCartVisible(true) } else { setCartHover(false); setCartVisible(false); } }} />
                            <span className="flex items-center -translate-y-2 -translate-x-2 justify-center text-white bg-red-700 rounded-lg w-4 h-4">{(props.cartData.products) ? props.cartData.products.length : 0}</span>
                        </div>
                        <div className="flex h-full items-center" onMouseLeave={() => { if (window.innerWidth >= 1024) { setTimeout(() => { if (socialHoverRef.current === false) { setSocialVisible(false) } }, "500") } }}>
                            <LinkIcon alt="link-icon" className={"hidden lg:flex h-6  " + (socialVisible ? "fill-curated-blue" : "fill-curated-gray")} onMouseOver={() => { if (window.innerWidth >= 1024) { setSocialVisible(true) } }} onClick={() => { if (socialVisible === false) { setAccountHover(false); setAccountVisible(false); setCartHover(false); setCartVisible(false); setMobileMenu(false); setSocialVisible(true) } else { setSocialHover(false); setSocialVisible(false); } }} />
                        </div>
                    </div>
                </div>
                <Transition className={"absolute w-full z-40"}
                    show={(searchVisible && !accountVisible && !cartVisible && !socialVisible && ((window.innerWidth>=1024) ? true : !mobileMenu))}
                    enter="transition ease-out duration-700 delay-300"
                    enterFrom="transform opacity-0 -translate-y-12"
                    enterTo="transform opacity-100  translate-y-0"
                    leave="transition ease-in duration-300 "
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 -translate-y-12"
                >
                    <div className="flex bg-white flex-col lg:mt-0.5">
                        <div className={"flex flex-col mt-14  lg:mt-16 bg-white z-20 items-center lg:px-14 justify-start lg:pb-3 px-5 "+((window.innerWidth>=1024) ? "" : "shadow-sm")}>
                            <div className="flex w-full flex-col-reverse lg:flex-row lg:px-16">
                                <div className="flex flex-row items-center justify-start w-full  lg:grow py-1.5">
                                    <p className="mr-0 lg:mr-4 text-tiny sm:text-base text-search-bar-text ">{searchParams.get('store') ? searchParams.get('store') : "All Stores"} {" / "} {(searchParams.get('category')) ? searchParams.get('category') : 'All Categories'} {" / "} {(searchParams.get('subCategory')) ? searchParams.get('subCategory') : 'All Subcategories'}</p>
                                </div>
                                <div className="flex flex-row w-full lg:w-5/6 z-10 gap-4">
                                    <div className="flex w-3/5  justify-start items-center bg-white border border-search-bar-text">
                                        <div className="flex justify-center item-center grow " >
                                            <input defaultValue={searchInputValue} onInput={(e) => { if (location.pathname !== "/products/") { navigate({ pathname: "/products/", search: createSearchParams({ search: e.target.value }).toString() }) } else { searchParams.set('page', 1); if (e.target.value === "") { searchParams.delete('search'); setSearchParams(searchParams) } else { searchParams.set('search', e.target.value); setSearchParams(searchParams) } } }} className="placeholder:text-search-bar-text block w-full py-1 pl-3 focus:outline-none text-sm sm:text-tiny" placeholder='Search a product'></input>
                                        </div>
                                        <div className="flex px-3 justify-end items-center h-full">
                                            <div className="flex h-full items-center">
                                                <SearchIcon alt="cart-icon" className="h-6 hover:fill-curated-blue " />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex w-2/5 justify-start items-center bg-white  border border-search-bar-text ">
                                        <Select
                                            placeholder="Sort By"
                                            className="text-sm sm:text-tiny grow focus:outline-none appearance-none"
                                            value={sortInputValue}
                                            styles={{ control: (provided, state) => ({ display: 'flex' }), option: (provided, state) => ({ backgroundColor: state.isSelected ? '#203864' : '#ffffff', color: state.isSelected ? '#ffffff' : '#000000', padding: 8, cursor: 'pointer' }) }}
                                            options={[{ value: 'priceMin', label: 'Price Min' },
                                            { value: 'priceMax', label: 'Price Max' },
                                            { value: 'nameAsc', label: 'Name A-Z' },
                                            { value: 'nameDes', label: 'Name Z-A' }]}
                                            onChange={(e) => { searchParams.set('page', 1); if (e.value === "") { searchParams.delete('sort'); setSearchParams(searchParams) } else { searchParams.set('sort', e.value); setSearchParams(searchParams) } }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
                <Transition className={"absolute w-full z-20"}
                    show={mobileMenu && !accountVisible && !cartVisible && !socialVisible}
                    enter="transition ease-out duration-700 delay-300"
                    enterFrom="transform opacity-0 -translate-x-12"
                    enterTo="transform opacity-100  translate-x-0"
                    leave="transition ease-in duration-300"
                    leaveFrom="transform opacity-100 translate-x-0"
                    leaveTo="transform opacity-0 -translate-x-12"
                >
                    <div className="flex flex-row lg:flex-col w-11/12 lg:w-auto bg-white lg:bg-transparent shadow-sm lg:shadow-none z-30 mt-1.5">
                        <div className="flex flex-col lg:flex-row gap-3 px-5 lg:px-20 pb-1.5 w-1/2 lg:w-full z-30 bg-white lg:shadow-sm h-screen lg:h-fit pt-14 lg:mt-14">
                            <div className="flex flex-col w-full lg:w-6/12 items-start lg:items-center cursor-pointer hover:text-curated-blue"
                                onMouseOver={() => { setCategoryMenu("livingroom"); setMenuHover(true) }}
                                onMouseLeave={() => { setMenuHover(false); setTimeout(() => { if (menuHoverRef.current === false) { setCategoryMenu("") } }, "500") }}>
                                <p className="font-semibold text-tiny sm:text-base md:text-tb w-full lg:w-fit lg:px-3">LIVING ROOM</p>
                            </div>
                            <div className="flex flex-col w-full lg:w-6/12 items-start lg:items-center cursor-pointer hover:text-curated-blue"
                                onMouseOver={() => { setCategoryMenu("diningroom"); setMenuHover(true) }}
                                onMouseLeave={() => { setMenuHover(false); setTimeout(() => { if (menuHoverRef.current === false) { setCategoryMenu("") } }, "500") }}>
                                <p className="font-semibold text-tiny sm:text-base md:text-tb w-full lg:w-fit lg:px-3">DINING ROOM</p>
                            </div>
                            <div className="flex flex-col w-full lg:w-6/12 items-start lg:items-center cursor-pointer hover:text-curated-blue"
                                onMouseOver={() => { setCategoryMenu("bedroom"); setMenuHover(true) }}
                                onMouseLeave={() => { setMenuHover(false); setTimeout(() => { if (menuHoverRef.current === false) { setCategoryMenu("") } }, "500") }}>
                                <p className="font-semibold text-tiny sm:text-base md:text-tb w-full lg:w-fit lg:px-3">BEDROOM</p>
                            </div>
                            <div className="flex flex-col w-full lg:w-6/12 items-start lg:items-center cursor-pointer hover:text-curated-blue"
                                onMouseOver={() => { setCategoryMenu("storage"); setMenuHover(true) }}
                                onMouseLeave={() => { setMenuHover(false); setTimeout(() => { if (menuHoverRef.current === false) { setCategoryMenu("") } }, "500") }}>
                                <p className="font-semibold text-tiny sm:text-base md:text-tb w-full lg:w-fit lg:px-3">STORAGE {" & "} MEDIA</p>
                            </div>
                            <div className="flex flex-col w-full lg:w-6/12 items-start lg:items-center cursor-pointer hover:text-curated-blue"
                                onMouseOver={() => { setCategoryMenu("office"); setMenuHover(true) }}
                                onMouseLeave={() => { setMenuHover(false); setTimeout(() => { if (menuHoverRef.current === false) { setCategoryMenu("") } }, "500") }}>
                                <p className="font-semibold text-tiny sm:text-base md:text-tb w-full lg:w-fit lg:px-3">OFFICE</p>
                            </div>
                            <div className="flex flex-col w-full lg:w-6/12 items-start lg:items-center cursor-pointer hover:text-curated-blue"
                                onMouseOver={() => { setCategoryMenu("outdoor"); setMenuHover(true) }}
                                onMouseLeave={() => { setMenuHover(false); setTimeout(() => { if (menuHoverRef.current === false) { setCategoryMenu("") } }, "500") }}>
                                <p className="font-semibold text-tiny sm:text-base md:text-tb w-full lg:w-fit lg:px-3">OUTDOOR</p>
                            </div>
                        </div>
                        <Transition className={"w-1/2 lg:w-full"}
                            show={categoryMenu !== ""}
                            enter="transition ease-out duration-700"
                            enterFrom="transform opacity-0 -translate-y-12"
                            enterTo="transform opacity-100  translate-y-0"
                            leave="transition ease-in duration-300"
                            leaveFrom="transform opacity-100 translate-y-0"
                            leaveTo="transform opacity-0 -translate-y-12">
                            <div className="flex flex-row lg:px-20 w-full h-full md:flex-row gap-3 text-tiny justify-center " >
                                <div className={"flex-col w-full lg:w-6/12 h-full items-start " + ((categoryMenu === "livingroom") ? "flex" : "hidden lg:flex ")}>
                                    <Transition className={"w-full h-full z-20"}
                                        show={categoryMenu === "livingroom"}
                                        enter="transition ease-out duration-700"
                                        enterFrom="transform opacity-0 -translate-y-12"
                                        enterTo="transform opacity-100  translate-y-0"
                                        leave="transition ease-in duration-300"
                                        leaveFrom="transform opacity-100 translate-y-0"
                                        leaveTo="transform opacity-0 -translate-y-12">
                                        <div className="w-full bg-white px-3 pb-3 pt-14 lg:pt-2 h-full lg:h-fit lg:shadow-sm" onMouseLeave={() => { setMenuHover(false); setCategoryMenu("") }} onMouseOver={() => { setMenuHover(true) }}>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Sofas"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Sofas</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Chaises"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Chaises</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Sectionals"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Sectionals</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Chairs"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Chairs</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Ottomans, Benches & Stools"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Ottomans, Benches {"&"} Stools</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Coffee Tables"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Coffee Tables</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Side Tables"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Side Tables</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.set("subCategory", "Console Tables"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Console Tables</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "LIVING ROOM"); searchParams.delete("subCategory"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>All Living Room</p>
                                        </div>
                                    </Transition>
                                </div>
                                <div className={"flex flex-col w-full lg:w-6/12 h-full items-start " + ((categoryMenu === "diningroom") ? "flex" : "hidden lg:flex ")}>
                                    <Transition className={"w-full h-full z-20"}
                                        show={categoryMenu === "diningroom"}
                                        enter="transition ease-out duration-700"
                                        enterFrom="transform opacity-0 -translate-y-12"
                                        enterTo="transform opacity-100  translate-y-0"
                                        leave="transition ease-in duration-300"
                                        leaveFrom="transform opacity-100 translate-y-0"
                                        leaveTo="transform opacity-0 -translate-y-12">
                                        <div className="w-full bg-white px-3 pb-3 pt-14 lg:pt-2 h-full lg:h-fit lg:shadow-sm" onMouseLeave={() => { setMenuHover(false); setCategoryMenu("") }} onMouseOver={() => { setMenuHover(true) }}>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.set("subCategory", "Dining Tables"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Dining Tables</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.set("subCategory", "Dining Chairs"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Dining Chairs</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.set("subCategory", "Dining Benches"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Dining Benches</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.set("subCategory", "Bar & Counter Stools"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Bar {"&"} Counter Stools</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.set("subCategory", "Bar Tables"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Bar Tables</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.set("subCategory", "Bar Carts & Storage"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Bar Carts {"&"} Storage</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.set("subCategory", "Kitchen Islands"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Kitchen Islands</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "DINING ROOM"); searchParams.delete("subCategory"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>All Dining Room</p>
                                        </div>
                                    </Transition>
                                </div>
                                <div className={"flex flex-col w-full lg:w-6/12 h-full items-start " + ((categoryMenu === "bedroom") ? "flex" : "hidden lg:flex ")}>
                                    <Transition className={"w-full h-full z-20"}
                                        show={categoryMenu === "bedroom"}
                                        enter="transition ease-out duration-700"
                                        enterFrom="transform opacity-0 -translate-y-12"
                                        enterTo="transform opacity-100  translate-y-0"
                                        leave="transition ease-in duration-300"
                                        leaveFrom="transform opacity-100 translate-y-0"
                                        leaveTo="transform opacity-0 -translate-y-12">
                                        <div className="w-full bg-white px-3 pb-3 pt-14 lg:pt-2 h-full lg:h-fit lg:shadow-sm" onMouseLeave={() => { setMenuHover(false); setCategoryMenu("") }} onMouseOver={() => { setMenuHover(true) }}>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "BEDROOM"); searchParams.set("subCategory", "Beds"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Beds</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "BEDROOM"); searchParams.set("subCategory", "Nightstands"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Nightstands</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "BEDROOM"); searchParams.set("subCategory", "Dressers & Chests"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Dressers {"&"} Chests</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "BEDROOM"); searchParams.set("subCategory", "Trunks"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Trunks</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "BEDROOM"); searchParams.set("subCategory", "Mirrors"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Mirrors</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "BEDROOM"); searchParams.delete("subCategory"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>All Bedroom</p>
                                        </div>
                                    </Transition>
                                </div>
                                <div className={"flex flex-col w-full lg:w-6/12 h-full items-start " + ((categoryMenu === "storage") ? "flex" : "hidden lg:flex ")}>
                                    <Transition className={"w-full h-full z-20"}
                                        show={categoryMenu === "storage"}
                                        enter="transition ease-out duration-700"
                                        enterFrom="transform opacity-0 -translate-y-12"
                                        enterTo="transform opacity-100  translate-y-0"
                                        leave="transition ease-in duration-300"
                                        leaveFrom="transform opacity-100 translate-y-0"
                                        leaveTo="transform opacity-0 -translate-y-12">
                                        <div className="w-full bg-white px-3 pb-3 pt-14 lg:pt-2 h-full lg:h-fit lg:shadow-sm" onMouseLeave={() => { setMenuHover(false); setCategoryMenu("") }} onMouseOver={() => { setMenuHover(true) }}>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "STORAGE & MEDIA"); searchParams.set("subCategory", "Sideboards"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Sideboards</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "STORAGE & MEDIA"); searchParams.set("subCategory", "Media Consoles"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Media Consoles</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "STORAGE & MEDIA"); searchParams.set("subCategory", "Bookcases"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Bookcases</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "STORAGE & MEDIA"); searchParams.set("subCategory", "Cabinets"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Cabinets</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "STORAGE & MEDIA"); searchParams.delete("subCategory"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>All Storage {"&"} Media</p>
                                        </div>
                                    </Transition>
                                </div>
                                <div className={"flex flex-col w-full lg:w-6/12 h-full items-start " + ((categoryMenu === "office") ? "flex" : "hidden lg:flex ")}>
                                    <Transition className={"w-full h-full z-20"}
                                        show={categoryMenu === "office"}
                                        enter="transition ease-out duration-700"
                                        enterFrom="transform opacity-0 -translate-y-12"
                                        enterTo="transform opacity-100  translate-y-0"
                                        leave="transition ease-in duration-300"
                                        leaveFrom="transform opacity-100 translate-y-0"
                                        leaveTo="transform opacity-0 -translate-y-12">
                                        <div className="w-full bg-white px-3 pb-3 pt-14 lg:pt-2 h-full lg:h-fit lg:shadow-sm" onMouseLeave={() => { setMenuHover(false); setCategoryMenu("") }} onMouseOver={() => { setMenuHover(true) }}>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OFFICE"); searchParams.set("subCategory", "Desks"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Desks</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OFFICE"); searchParams.set("subCategory", "Desks Chairs"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Desks Chairs</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OFFICE"); searchParams.set("subCategory", "Office Storage"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Office Storage</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OFFICE"); searchParams.delete("subCategory"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>All Office</p>
                                        </div>
                                    </Transition>
                                </div>
                                <div className={"flex flex-col w-full lg:w-6/12 h-full items-start " + ((categoryMenu === "outdoor") ? "flex" : "hidden lg:flex ")}>
                                    <Transition className={"w-full h-full z-20"}
                                        show={categoryMenu === "outdoor"}
                                        enter="transition ease-out duration-700"
                                        enterFrom="transform opacity-0 -translate-y-12"
                                        enterTo="transform opacity-100  translate-y-0"
                                        leave="transition ease-in duration-300"
                                        leaveFrom="transform opacity-100 translate-y-0"
                                        leaveTo="transform opacity-0 -translate-y-12">
                                        <div className="w-full bg-white px-3 pb-3 pt-14 lg:pt-2 h-full lg:h-fit lg:shadow-sm" onMouseLeave={() => { setMenuHover(false); setCategoryMenu("") }} onMouseOver={() => { setMenuHover(true) }}>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OUTDOOR"); searchParams.set("subCategory", "Outdoor Lounging"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Outdoor Lounging</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OUTDOOR"); searchParams.set("subCategory", "Outdoor Dining"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Outdoor Dining</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OUTDOOR"); searchParams.set("subCategory", "Outdoor Accesories"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>Outdoor Accesories</p>
                                            <p className="cursor-pointer hover:text-curated-blue" onClick={() => { searchParams.set('page', 1); searchParams.set("category", "OUTDOOR"); searchParams.delete("subCategory"); setSearchParams(searchParams); setCategoryMenu(""); if (location.pathname !== "/products/") { navigate("/products/?" + searchParams) }; setMobileMenu(false) }}>All Outdoor</p>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </Transition>
            </header>
            <div className="flex w-screen flex-row justify-end z-20">
                <div className="fixed w-24 mr-8 lg:mr-28 z-20" onMouseOver={() => { setAccountHover(true) }}
                    onMouseLeave={() => { setAccountHover(false); setAccountVisible(false) }}>
                    {!(location.pathname === "/signup") && !(location.pathname === "/login") && !(location.pathname === "/checkout")   && (
                        <Transition className={"w-full z-20"}
                            show={accountVisible}
                            enter="transition ease-out duration-700 delay-300"
                            enterFrom="transform opacity-0 -translate-y-12"
                            enterTo="transform opacity-100  translate-y-0"
                            leave="transition ease-in duration-300"
                            leaveFrom="transform opacity-100 translate-y-0"
                            leaveTo="transform opacity-0 -translate-y-12">
                            <Account />
                        </Transition>
                    )}
                </div>
                <div className="fixed w-11/12 lg:w-auto lg:mr-20 z-20" onMouseOver={() => { setCartHover(true) }}
                    onMouseLeave={() => { setCartHover(false); setCartVisible(false) }}>
                    {!(location.pathname === "/signup") && !(location.pathname === "/login") && !(location.pathname === "/checkout")   && (
                        <Transition className={"w-full z-20"}
                            show={cartVisible}
                            enter="transition ease-out duration-700 delay-300"
                            enterFrom="transform opacity-0 translate-x-12"
                            enterTo="transform opacity-100  translate-x-0"
                            leave="transition ease-in duration-300"
                            leaveFrom="transform opacity-100 translate-x-0"
                            leaveTo="transform opacity-0 translate-x-12">
                            <Cart cartData={props.cartData} getCart={props.getCart} addToCart={props.addToCart} supInCart={props.supInCart} />
                        </Transition>
                    )}
                </div>
                <div className="fixed hidden lg:flex md:px-11 z-20" onMouseOver={() => {setSocialHover(true)}}
                    onMouseLeave={() => {setSocialHover(false); setSocialVisible(false)}}>
                    {!(location.pathname === "/signup") && !(location.pathname === "/login") && !(location.pathname === "/checkout")  && (
                        <Transition className={"w-full z-20"}
                            show={socialVisible}
                            enter="transition ease-out duration-700 delay-300"
                            enterFrom="transform opacity-0 -translate-y-12"
                            enterTo="transform opacity-100  translate-y-0"
                            leave="transition ease-in duration-300"
                            leaveFrom="transform opacity-100 translate-y-0"
                            leaveTo="transform opacity-0 -translate-y-12">
                            <Social />
                        </Transition>
                    )}
                </div>
            </div>
        </div>
    );
}



export default Header;