import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as AccountIcon } from '../../img/account-icon.svg';
import { useEffect, useState } from 'react';

const LogIn = (props) => {

    const [emailInput, setEmailInput] = useState();
    const [passwordInput, setPasswordInput] = useState();
    const navigate = useNavigate();

    return (

        <div onClick={() => navigate(-1)} className="flex fixed w-screen h-screen items-center justify-center bg-search-bar-text bg-opacity-75 z-40" >
            <div onClick={(e) => e.stopPropagation()} className="flex items-center justify-center w-96 h-auto flex-col gap-5  bg-white border border-search-bar-gray  shadow-sm ">
                <div className="flex flex-row mt-5"><p className="text-lg text-black">Please Login Into Your Account</p>
                </div>
                <div className="flex w-80 bg-white border border-search-bar-gray shadow-sm ">
                    <input value={emailInput} onInput={e => setEmailInput(e.target.value)} className="placeholder:text-search-bar-text block w-80 bg-white border-search-bar-gray 
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Email'></input>
                </div>
                <div className="flex w-80 bg-white border border-search-bar-gray  shadow-sm ">
                    <input type="password" value={passwordInput} onInput={e => setPasswordInput(e.target.value)} className="placeholder:text-search-bar-text block w-80 bg-white border-search-bar-gray 
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Password'></input>
                </div>
                <div className="flex w-32 bg-curated-blue border border-curated-blue shadow-sm ">
                    <button onClick={() => props.loginAccount(emailInput, passwordInput)} className="text-white w-80 bg-curated-blue border-search-bar-gray 
                       py-2 pr focus:outline-none sm:text-sm" >Log In</button>
                </div>
                <div className="flex py-2 flex-row">
                    <p className=" 
                            focus:outline-none text-sm flex flex-row" >Forgot&nbsp;</p>
                        <Link to="/reset-password">
                            <p className="text-curated-blue font-semibold">Password?</p>
                        </Link>
                        <p>&nbsp;&nbsp;|&nbsp;&nbsp;</p>
                    <p className=" focus:outline-none text-sm" >Don't Have An Account?&nbsp;</p>
                        <Link to="/signup">
                            <p className="text-curated-blue font-semibold">Sign Up</p>
                        </Link>
                </div>
            </div>
        </div>

    );
}



export default LogIn;