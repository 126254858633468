import { useLocation } from 'react-router-dom';
import Home from '../home.page';
import ProductDetails from '../productDetails.page';
import Products from '../products.page';

const Conditional = (props) => {

    const location = useLocation();
    const previousPath = location.state?.previousPath;

    if (previousPath) {
        if (previousPath === "/products/") { return (<Products getProducts={props.getProducts} searchData={props.searchData} pagesData={props.pagesData} cartCode={props.cartCode} getFeatures={props.getFeatures} addToCart={props.addToCart} />) }
        else if ((previousPath.length > 10) && (previousPath.slice(0, 10) === "/products/")) { return (<ProductDetails path={previousPath} cartCode={props.cartCode} addToCart={props.addToCart} />) }
        else { return (<Home cartCode={props.cartCode} productData={props.productData} cartData={props.cartData} getFeatures={props.getFeatures} addToCart={props.addToCart} />) }
    }  else { return (<Home cartCode={props.cartCode} productData={props.productData} cartData={props.cartData} getFeatures={props.getFeatures} addToCart={props.addToCart} />) }
}

export default Conditional;