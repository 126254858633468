import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cart from "./checkout/cart.page";
import Shipping from "./checkout/shipping.page";
import Payment from "./checkout/payment.page";

const Checkout = (props) => {

    const [activeWin, setActiveWin] = useState(1);
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState(1);
    const [lastName, setLastName] = useState(1);
    const [email, setEmail] = useState(1);
    const [phone, setPhone] = useState(1);
    const [address, setAddress] = useState(1);
    const [delivery, setDelivery] = useState(1);

    useEffect(() => {

        if (localStorage.getItem("activeWinChkt")) {
            setActiveWin(parseFloat(localStorage.getItem("activeWinChkt")));
        }

    }, []);

    return (
        <div onClick={() => navigate(-1)} className="flex fixed w-screen h-screen items-center justify-center bg-search-bar-text bg-opacity-75 z-40" >
            <div onClick={(e) => e.stopPropagation()} className="flex mt-36 items-center mb-28 justify-center w-auto h-auto flex-col gap-5 bg-white border border-search-bar-gray shadow-sm ">
                <div className="flex flex-col fixed px-5 w-full h-full md:w-4/5 md:h-4/5 bg-white py-3">
                        <div className="flex flex-row w-auto text-center text-lg">
                            <div className="flex h-fit gap-1 w-1/3 justify-center">
                                <p className={"w-fit px-6 pb-1.5 " + ((activeWin === 1) ? 'text-black border-black font-semibold border-b-[0.1rem]' : 'text-search-bar-text font-normal')}>Shopping Cart</p>
                            </div>
                            <div className="flex h-fit gap-1 w-1/3 justify-center">
                                <p className={"w-fit px-6 pb-1.5 " + ((activeWin === 2) ? 'text-black border-black font-semibold border-b-[0.1rem]' : 'text-search-bar-text font-normal')}>Shipping Details</p>
                            </div>
                            <div className="flex h-fit gap-1 w-1/3 justify-center">
                                <p className={"w-fit px-6 pb-1.5 " + ((activeWin === 3) ? 'text-black border-black font-semibold border-b-[0.1rem]' : 'text-search-bar-text font-normal')}>Payment Options</p>
                            </div>
                        </div>
                        {
                        (activeWin === 1) && (
                            <Cart cartData={props.cartData} getCart={props.getCart} addToCart={props.addToCart} supInCart={props.supInCart} next={() => { setActiveWin(2); localStorage.setItem("activeWinChkt", "2"); }} />
                        )
                    }{
                        (activeWin === 2) && (
                            <Shipping orderData={props.orderData} checkOrder={props.checkOrder} cartData={props.cartData} getCart={props.getCart} addToCart={props.addToCart} supInCart={props.supInCart} prev={() => { setActiveWin(1); localStorage.setItem("activeWinChkt", "1"); }} next={(fname, lname, email, phone, address, delivery) => {
                                setActiveWin(3); localStorage.setItem("activeWinChkt", "3");
                                setFirstName(fname); setLastName(lname); setEmail(email); setPhone(phone); setAddress(address); setDelivery(delivery)
                            }} />
                        )
                    }
                    {
                        (activeWin === 3) && (
                            <Payment firstName={firstName} lastName={lastName} email={email} phone={phone} address={address} delivery={delivery} processPayment={props.processPayment} clientSecret={props.clientSecret} cartData={props.cartData} getCart={props.getCart} addToCart={props.addToCart} supInCart={props.supInCart} prev={() => { setActiveWin(2); localStorage.setItem("activeWinChkt", "2"); }} />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Checkout;