import React from "react";
import ImageGallery from "react-image-gallery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import  LoadingIcon  from '../img/loading.svg';



const Product = (props) => {

    const [adding, setAdding] = useState("Add To Cart");
    const images = [];
    const {pathname} = useLocation();
    const navigate = useNavigate();
    let scroll = 0;
    

    for (let i = 0; i < props.photos.length; i++) {
        images.push({
            renderItem: (src) => RenderItem(src = props.photos[i].image_url),
        }
        )
    }

    useEffect(() => {

        if(adding==="Adding ..."){
        if(props.cartCode.id === props._id){
            if(props.cartCode.status === 200){
                setAdding("Added!");
                setTimeout(()=>{setAdding("Add To Cart")},"2000")
            }
            else if(props.cartCode.status === 209){
                setAdding("Out Of Stock");
                setTimeout(()=>{setAdding("Add To Cart")},"2000")
            }
            else if(props.cartCode.status === 211){
                setAdding(props.stock+" in Stock");
                setTimeout(()=>{setAdding("Add To Cart")},"2000")
            }
            else{
                setAdding("Error No Added!");
                setTimeout(()=>{setAdding("Add To Cart")},"2000")
            }
        }
        
    }
       

    }, [props.cartCode, props._id]);

    return (
        <div onClick={()=>{scroll = window.scrollY; navigate("/products/" + props.url, {state: { previousPath: pathname, prevSearchParams: props.searchParams, scrollPositionY: scroll}})}} className="flex w-full justify-self-center cursor-pointer">
            <div className="flex w-full  gap-0 flex-col items-center ">
                <div className="flex shrink w-full  mb-1  items-center justify-center"  onClick={(e) => e.stopPropagation()}>
                    <ImageGallery showBullets={true} onClick={()=>{scroll = window.scrollY; navigate("/products/" + props.url, {state:{ previousPath: pathname, prevSearchParams: props.searchParams, scrollPositionY: scroll}})}} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} className="flex" lazyLoad={true} items={images} />
                </div>
                
                <div className="flex flex-row gap-1 w-full pb-3">
                    <div className="flex justify-start grow">
                        <p className="text-xs md:text-sm border-b border-black w-18 px-2">{((props.stock>0) ? "In Stock" : "Sold Out")}</p>
                    </div>
                    <p className="text-sm text-search-bar-text"></p>
                </div>
                <div className="flex flex-col gap-1 w-full">
                    <p className="text-tiny md:text-base leading-5 h-14">{props.name}</p>
                    <div className="flex flex-row w-full items-end">
                        <div className="flex flex-col justify-start">
                        <p className="text-sm md:text-tiny line-through">${props.purPrice}</p>
                        <p className="text-sm md:text-tiny ">${props.price}</p>
                        </div>
                        <div className="flex w-full justify-end">
                            <div className={"flex w-3/5 justify-center  flex-row border  px-2 h-1/2 "+((adding==="Add To Cart") ? "transition ease-out duration-500 border-curated-blue bg-curated-blue" : (adding==="Added!") ? " transition ease-out duration-500 border-green-700 bg-green-700" : (adding==="Adding ...") ? " transition ease-out duration-500 border-curated-gray bg-curated-gray" : "transition ease-out duration-500 border-red-700 bg-red-700")}>
                                <button onClick={(e) => {e.stopPropagation(); if(adding==="Add To Cart"){ props.addToCart(props._id, 1); setAdding("Adding ..."); }}} className="w-full text-white text-xs md:text-sm text-center  
                          focus:outline-none" >{adding}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
}

const RenderItem = (src) => {
    return (<div className="flex h-[10rem] md:h-[16rem]  items-center justify-center">
        <LazyLoadImage src={src} effect="opacity" className="w-40 md:w-64"></LazyLoadImage>
    </div>
    );
}


export default Product;