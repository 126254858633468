import { React, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as AccountIcon } from '../../img/account-icon.svg';
const SignUp = (props) => {

    const [fnameInput, setfnameInput] = useState();
    const [lnameInput, setlnameInput] = useState();
    const [emailInput, setemailInput] = useState();
    const [passwordInput, setpasswordInput] = useState();
    const [rpasswordInput, setrpasswordInput] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    return (

        <div onClick={() => navigate(-1)} className={("flex fixed w-screen h-screen items-center justify-center bg-search-bar-text bg-opacity-75 z-40 "+ (location.pathname) )}>
            <div onClick={(e) => e.stopPropagation()} className="flex items-center justify-center w-96 h-auto flex-col gap-5 bg-white border border-search-bar-gray shadow-sm ">
                <div className="flex flex-row mt-5"><p className="text-lg text-black">Create An Account</p>
                </div>
                <div className="flex w-80 bg-white border border-search-bar-gray shadow-sm ">
                    <input value={fnameInput} onInput={e => setfnameInput(e.target.value)} className="placeholder:text-search-bar-text block w-80 bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='First Name'></input>
                </div>
                <div className="flex w-80 bg-white border border-search-bar-gray shadow-sm ">
                    <input value={lnameInput} onInput={e => setlnameInput(e.target.value)} className="placeholder:text-search-bar-text block w-80 bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Last Name'></input>
                </div>
                <div className="flex w-80 bg-white border border-search-bar-gray shadow-sm ">
                    <input value={emailInput} onInput={e => setemailInput(e.target.value)} className="placeholder:text-search-bar-text block w-80 bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Email'></input>
                </div>
                <div className="flex w-80 bg-white border border-search-bar-gray shadow-sm ">
                    <input type="password" value={passwordInput} onInput={e => setpasswordInput(e.target.value)} className="placeholder:text-search-bar-text block w-80 bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Create A Password'></input>
                </div>
                <div className="flex w-80 bg-white border border-search-bar-gray shadow-sm ">
                    <input type="password" value={rpasswordInput} onInput={e => setrpasswordInput(e.target.value)} className="placeholder:text-search-bar-text block w-80 bg-white border-search-bar-gray rounded-md
             py-2 pl-3 pr focus:outline-none sm:text-sm" placeholder='Repeat Your Password'></input>
                </div>
                <div className="flex w-32 bg-curated-blue border border-curated-blue shadow-sm ">
                    <button onClick={() => props.signUpAccount(fnameInput, lnameInput, emailInput, passwordInput)} className="text-white w-80 bg-curated-blue border-search-bar-gray rounded-md
             py-2 pr focus:outline-none sm:text-sm" >Sign Up</button>
                </div>
                <div className="flex flex-row py-2 ">
                    <p className=" focus:outline-none text-sm" >Have An Account?&nbsp;</p>
                        <Link to="/login">
                            <p className="text-curated-blue font-semibold">Log In</p>
                        </Link>
                </div>
            </div>
        </div>

    );

}

export default SignUp;