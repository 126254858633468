import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { LazyLoadImage } from "react-lazy-load-image-component";

import PaymentForm from "./paymentForm.page";

const stripePromise = loadStripe("pk_live_51Kl0pjC7xXbdjO6Qx2IUxbH4v0J5rBpHcqK79qW4JhU8Kd8IdznshuvHn5ioYdRpV0Aw3lFWraHWxD15zcezQcoy000PA22mp9");

const Payment = (props) => {
    const [ clientSecret, setClientSecret ] = useState();

    const [ subtotal, setSubtotal ] = useState(0)
    const [ taxes, setTaxes ] = useState(0);
    const [ total, setTotal ] = useState(0);
    const [ cartActive, setCartActive ] = useState(false)
    const [ payActive, setPayActive ] = useState(false)

    const paymentRef = useRef(null);

    useEffect(() => {
        if (props.cartData.products){
           
            setSubtotal(props.cartData.subTotal);
            setTotal(props.cartData.total);
            setTaxes((props.cartData.totalTaxes+props.cartData.stripeFee).toFixed(2));
            setCartActive(true);
            if(props.cartData.products[0]){
                setPayActive(true);
            }else{setPayActive(false);}
        }
        else{
            setPayActive(false);
            setSubtotal(0);
            setTotal(0);
        }
    }, [props]);

    useEffect(() => {

       pay();


    }, []);

    useEffect(() => {

        setClientSecret(props.clientSecret);

    }, [props.clientSecret]);

    const pay = () => {
        props.processPayment(props.firstName, props.lastName, props.email, props.phone, props.address, props.delivery);
    }

    const appearance = {
        theme: 'stripe',
        rules: {
            '.Input': {
                fontFamily:'Minerva-Modern',
                border: '0.5px solid #E0E6EB',
                borderRadius: '0px',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },
            '.Label': {
                fontFamily:'Minerva-Modern',
            },
            '.Tab': {
                fontFamily:'Minerva-Modern',
                border: '0.5px solid #E0E6EB',
                borderRadius: '0px',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },
        }
    };


    const options = {
        clientSecret,
        appearance,
        fonts : [{
            cssSrc: 'https://assets.curatedhomeconcept.com/index.css'
          }]
    };

    return (
      
            <div className="flex flex-col w-auto h-full bg-white mt-5">
                <div className="flex flex-row w-auto gap-6 h-full mb-5">
                <div className="flex flex-col gap-3 grow w-3/5">
                    <p className="font-semibold text-tiny">Payment Options</p>
                    <hr className="" />
                    <div className="flex flex-col grow">
                        {clientSecret && (<Elements  options={options} stripe={stripePromise}>
                            <PaymentForm ref={paymentRef}/>
                        </Elements>)}
                    </div>
                </div>
                <div className="flex flex-col w-2/5 h-full gap-6">
                    <div className="flex flex-col gap-3">
                        <p className="font-semibold text-tiny">Shopping Cart</p>
                        <hr className="" />
                        <div className="flex flex-col  h-40 overflow-y-scroll scrollbar-hide gap-3">
                            {cartActive &&
                                (Object.keys(props.cartData.products).map(item => {
                                    return <Item key={props.cartData.products[item]._id} productId={props.cartData.products[item].productId} photo={props.cartData.products[item].photo} name={props.cartData.products[item].name} quantity={props.cartData.products[item].quantity} price={props.cartData.products[item].price} totalPrice={props.cartData.products[item].totalPrice} supInCart={props.supInCart} addToCart={props.addToCart} />
                                }))
                            }</div>
                    </div>
                    <div className="flex flex-col gap-3 ">
                        <p className="font-semibold text-tiny">Summary</p>
                        <hr className="h-1" />
                        <div className="flex flex-row">
                            <div className="flex flex-col gap-4 ">
                                <p className="text-tiny">Subtotal</p>
                                <p className="text-tiny ">Shipping</p>
                                <p className="text-tiny">Tax {'&'} Fees</p>
                            </div>
                            <div className="flex flex-col gap-4 grow text-right justify-end">
                                <p className="text-tiny">${subtotal.toFixed(2)}</p>
                                <p className="text-tiny">At Chkt.</p>
                                <p className="text-tiny">${taxes}</p>
                            </div>
                        </div>
                        <hr className="h-1" />
                        <div className="flex flex-row">
                            <div className="flex flex-col gap-4 ">
                                <p className=" text-tiny font-semibold">Total</p>
                            </div>
                            <div className="flex flex-col gap-4 w-full text-right justify-end">
                                <p className=" text-tiny font-semibold ">${total.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="flex flex-row">
                <div className="flex items-end w-full h-full justify-start">
                        <div className="flex w-52 bg-curated-blue shadow-sm ">
                            <button onClick={() => { props.prev() }} className="text-white text-tiny w-full text-center 
                    py-2 pr focus:outline-none" >Prev: Shipping</button>
                        </div>
                    </div>
                <div className="flex items-end w-full h-full justify-end grow">
                    <div className={"flex w-52 shadow-sm " + (!(cartActive && clientSecret) ? 'bg-curated-gray':'bg-curated-blue')}>
                    <button disabled={!(cartActive && clientSecret)}  onClick={(e) => {paymentRef.current.payNow(e)}} className="text-white text-tiny w-full text-center 
                        py-2 pr focus:outline-none" >Pay Now</button>
                    </div>
                    </div>
                </div>
            </div>
    );
}

const Item = (props) => {
    return (
        <div className="flex gap-4 items-center">
            <div className="flex w-12 h-12 items-center justify-center ">
                <div className="flex h-full">
                    <LazyLoadImage src={props.photo} className="h-full" visibleByDefault={props.photo === "/img.jpg"} />
                </div>
            </div>
            <div className="flex flex-col gap-1 w-full">
                <p className="text-base">{props.name}</p>
                <div className="flex flex-row w-full items-center">
                    <p className="text-tiny">{props.quantity}{(props.quantity === 1) ? " unit" : " units"}</p>
                    <div className="flex grow justify-end items-end">
                        <div className="flex  flex-row  ">
                            <p className="text-tiny">${props.totalPrice.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;

