import { Link } from "react-router-dom";
import { ReactComponent as FacebookLogo } from '../../img/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../img/instagram-logo.svg';
import { ReactComponent as PinterestLogo } from '../../img/pinterest-logo.svg';
import { ReactComponent as HouzzLogo } from '../../img/houzz-logo.svg';

const Footer = () => {
    return (
        <footer className="bg-curated-footer w-full px-8 py-8">
            <div className="flex flex-col w-full md:flex-row gap-2 lg:gap-10 text-tiny justify-center " >
                <div className="flex flex-row w-full gap-5 lg:gap-10 justify-center">
                <div className="flex flex-col w-6/12 items-center">
                    <div className="w-40">
                        <p className="font-semibold w-fit">PRODUCTS</p>
                        <Link to="/products/?category=OFFICE" ><p className="hover:text-curated-blue">Office</p></Link>
                        <Link to="/products/?category=LIVING+ROOM" ><p className="hover:text-curated-blue">Living Room</p></Link>
                        <Link to="/products/?category=DINING+ROOM" ><p className="hover:text-curated-blue">Dining Room</p></Link>
                        <Link to="/products/?category=STORAGE+%26+MEDIA" ><p className="hover:text-curated-blue">Storage {" & "} Media</p></Link>
                        <Link to="/products/?category=BEDROOM" ><p className="hover:text-curated-blue">Bedroom</p></Link>
                        <Link to="/products/?category=OUTDOOR" ><p className="hover:text-curated-blue">Outdoor</p></Link>
                    </div>
                </div>
                <div className="flex flex-col w-6/12 items-center">
                    <div className="w-40">
                        <p className="font-semibold w-fit">ABOUT US</p>
                        <a href="https://thecurated.group" ><p className="hover:text-curated-blue">The CURATED. Group</p></a>
                        <a href="https://thecurated.group/about-us" ><p className="hover:text-curated-blue">Team CURATED.</p></a>
                        <Link to="/about-us" ><p className="hover:text-curated-blue">Address {"&"} Location</p></Link>
                        <a href="https://thecurated.group/blog" ><p className="hover:text-curated-blue">Blog</p></a>
                        <Link to="/contact-us" ><p className="hover:text-curated-blue">Contact Us</p></Link>
                    </div>
                </div>
                </div>
                <div className="flex flex-row w-full gap-5 lg:gap-10 justify-center">
                <div className="flex flex-col w-6/12 items-center">
                    <div className="w-40">
                        <p className="font-semibold w-fit">SERVICES</p>
                        <Link to="/" ><p className="hover:text-curated-blue">FAQ</p></Link>
                        <Link to="/" ><p className="hover:text-curated-blue">Refund Policy</p></Link>
                        <Link to="/" ><p className="hover:text-curated-blue">Returns {"&"} Exchanges</p></Link>
                        <Link to="/" ><p className="hover:text-curated-blue">Pickup Information</p></Link>
                        <Link to="/" ><p className="hover:text-curated-blue">Schedule Delivery</p></Link>
                    </div>
                </div>
                <div className="flex flex-col gap-3 w-6/12 items-center">
                    <div className="w-40">
                        <p className="font-semibold w-fit">Get in touch</p>
                        <div className="flex flex-col">
                            <a href="tel:+1 (786) 751-3405" className="w-fit h-fit underline hover:text-curated-blue">{"+1 (305) 615-5141"}</a>
                            <a href="mailto:coordinator@thecurated.group" className="w-fit h-fit underline hover:text-curated-blue">Email us</a>
                        </div>
                    </div>
                    <div className="w-40">
                        <p className="font-semibold w-fit">Follow us</p>
                        <div className="flex flex-row pt-2 gap-3">
                            <a href="https://www.instagram.com/curatedhomeconcept/" className="w-fit h-fit"><InstagramLogo alt="instagram-logo" className="h-5 w-5 hover:fill-curated-blue" /></a>
                            <a href="https://www.facebook.com/curatedhomeconcept" className="w-fit h-fit"><FacebookLogo alt="facebook-logo" className="h-5 w-5 hover:fill-curated-blue" /></a>
                            <a href="https://www.pinterest.com/thinkcurated/" className="w-fit h-fit"><PinterestLogo alt="pinterest-logo" className="h-5 w-5 hover:fill-curated-blue" /></a>
                            <a href="https://www.houzz.com/professionals/interior-designers-and-decorators/curated-pfvwus-pf~1101153491" className="w-fit h-fit"><HouzzLogo alt="houzz-logo" className="h-5 w-5 hover:fill-curated-blue" /></a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="flex flex-col text-tiny items-center gap-2 mt-14 justify-center">
                <div className="flex flex-col md:flex-row justify-center">
                    <div className="flex flex-row justify-center"><p>Made with</p><p className="mt-0.5 text-curated-blue">&nbsp; &#10084; &nbsp;</p><p>by Team CURATED.&nbsp;</p></div><a className="hover:text-curated-blue" href="https://maps.apple.com/maps?q=3022+NW+73rd+St+Suite+5,+Miami,+FL+33147" target="_blank" rel="noopener noreferrer"><p className="underline"> 3022 NW 73rd St Suite 5, Miami, FL 33147.</p></a>
                </div>
                <div>
                    <p>© 2023 Curated. All rights reserved.</p>
                </div>
                <div className="flex felx-row text-sm text-curated-gray">
                    <Link to="/terms-conditions"><p className="hover:text-curated-blue">Terms {"&"} Conditions</p></Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/site-map"><p className="hover:text-curated-blue">Site Map</p></Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/privacy-policy"><p className="hover:text-curated-blue">Privacy Policy</p></Link>
                </div>
            </div>

        </footer>

    );
}

export default Footer;