import { Link } from "react-router-dom";
import Product from "./product.page";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Home(props) {

  let products = [];

  for (let i = 0; i < props.productData.length; i++) {
    products.push(<Product cartCode={props.cartCode} stock={props.productData[i].stock} photos={props.productData[i].photos} name={props.productData[i].name} url={props.productData[i].url} price={props.productData[i].price} purPrice={props.productData[i].purPrice} key={props.productData[i]._id} _id={props.productData[i]._id} addToCart={props.addToCart}/>);
  }

  return (
    <div className="w-full h-full">
      <div className="pt-12 h-96 md:h-[36rem] grid">
        <div className="bg-[url('../img/home.webp')] bg-cover bg-origin-border bg-center" >
          <div className="flex flex-col bg-opacity-40 bg-search-bar-text w-full h-full items-center text-center justify-center">
          <h1 className="text-white text-base md:text-[4rem] px-4">OUTLET FURNITURE AND HOME DECOR</h1>
          <h2 className="text-white text-base md:text-[3rem] mb-14 px-4">Gently used, in stock and on sale!</h2>
            <Link to="/products/?collection=Outlet"><div className="py-2 px-6 bg-white mb-6">
              <h1 className="text-black text-base">Shop Outlet Furniture</h1>
            </div></Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center pt-10 px-4 md:px-20">
        <p className="text-lg md:text-3xl ">Shop By Room</p>
        <div className="grid grid-cols-4 grid-rows-2 gap-1 md:gap-5 w-full h-52 md:h-[36rem]  pt-3">
          <Link to="/products/?category=OFFICE" className="grid">
            <div className="bg-[url('../img/office.webp')] bg-cover bg-origin-border bg-bottom" >
              <div className="flex bg-opacity-40 bg-search-bar-text w-full h-full items-center justify-center">
                <h1 className="text-white text-base md:text-xl">Office</h1>
              </div> 
            </div>
          </Link>
          <Link to="/products/?category=LIVING+ROOM" className="grid col-span-2">
            <div className="bg-[url('../img/living-room.webp')] bg-cover bg-origin-border bg-bottom" >
              <div className="flex bg-opacity-40 bg-search-bar-text w-full h-full items-center justify-center">
                <h1 className="text-white text-base md:text-xl">Living Room</h1>
              </div>
            </div>
          </Link>
          <Link to="/products/?category=BEDROOM" className="grid">
            <div className="bg-[url('../img/bedroom.webp')] bg-cover bg-origin-border bg-bottom" >
              <div className="flex bg-opacity-40 bg-search-bar-text w-full h-full items-center justify-center">
                <h1 className="text-white text-base md:text-xl">Bedroom</h1>
              </div>
            </div>
          </Link>
          <Link to="/products/?category=DINING+ROOM" className="grid col-span-2">
            <div className="bg-[url('../img/dining-room.webp')] bg-cover bg-origin-border bg-bottom " >
              <div className="flex bg-search-bar-text w-full h-full bg-opacity-40 items-center justify-center">
                <h1 className="text-white text-base md:text-xl">Dining Room</h1>
              </div>
            </div>
          </Link>
          <Link to="/products/?category=STORAGE+%26+MEDIA" className="grid">
            <div className="bg-[url('../img/storage-and-media.webp')] bg-cover bg-origin-border bg-bottom" >
              <div className="flex bg-opacity-40 bg-search-bar-text w-full h-full items-center justify-center">
                <h1 className="text-white text-center text-base md:text-xl">Storage {" & "} Media</h1>
              </div>
            </div>
          </Link>
          <Link to="/products/?category=OUTDOOR" className="grid">
            <div className="bg-[url('../img/outdoor.webp')] bg-cover bg-origin-border bg-bottom" >
              <div className="flex bg-opacity-40 bg-search-bar-text w-full h-full items-center justify-center">
                <h1 className="text-white text-base md:text-xl">Outdoor</h1>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-10 px-4 md:px-20">
        <p className="text-lg md:text-3xl">Today's Features</p>
        <div className="grid grid-cols-2 gap-5 md:gap-10 w-full py-3 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 items-center justify-center">
              {products}
        </div>
      </div>
      <div className="hidden flex-col pt-10 items-center">
        <p className="text-3xl ">Shop Our Instagram</p>
        <p className="text-tiny">Shop the looks from our latest design projects</p>
      </div>
      <div className="hidden items-center pt-4 pb-10 snap-x px-28 overflow-auto">
        <div className="flex  snap-x  overflow-x-auto gap-6 scrollbar-hide">
          <div className="shrink-0 snap-center w-[36rem]">
            
          </div>
          <div className="shrink-0 snap-center w-[36rem] ">
            
          </div>
          <div className="snap-center shrink-0 w-[36rem] ">
           
          </div>
          <div className="snap-center shrink-0 w-[36rem]">
           
          </div>
          <div className="snap-center shrink-0 w-[36rem]">
            
          </div>
          <div className="snap-center shrink-0 w-[36rem]">
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;